import { http, createConfig, injected } from '@wagmi/core'
import {bsc , base } from '@wagmi/core/chains'

export const config = createConfig({
  chains: [base],
  connectors: [injected()], 
  transports: {
    [base.id]: http(),
   // [bscTestnet.id]: http()
  },
})